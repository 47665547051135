<template>
  <div>
    <BaseBreadcrumb :title="'Encuesta'">
      <template v-slot:options-left>
        <v-btn icon small class="mr-2" v-on:click="atras"><v-icon>mdi-arrow-left</v-icon></v-btn>
      </template>
      <template v-slot:options-center>
        <h3 v-if="total">{{ count + 1 }}/{{ total }}</h3>
      </template>
      <template v-slot:options-right>
        <div>
          <span class="text-caption red--text mr-4">{{ messageFormError }}</span>
          <v-btn v-on:click="save" small color="primary">Enviar</v-btn>
        </div>
      </template>
    </BaseBreadcrumb>
    <div style="padding-top: 70px;">
      <v-container fluid class="pa-0" v-if="mostrarEncuesta">
        <div class="px-5 mx-10">
          <v-row>
            <v-col cols="12">
              <v-form ref="form" v-model="validate" lazy-validation>
                <datos-encuesta
                  :surveyFormat="surveyFormat"
                  :headOffices="headOffices"
                  :businessUnits="businessUnits"
                ></datos-encuesta>
                <seccion-encuesta
                  v-for="(section, i) in surveyFormat.sections"
                  :key="i"
                  :position="i"
                  :valid="valid"
                  :number="numberInActive(section.id)"
                  :total="sectionsActives.length"
                  :section="section"
                  :typesQuestion="typesQuestion"
                  :showFirstSection="surveyFormat.showFirstSection"
                ></seccion-encuesta>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import DatosEncuesta from '@/gquest/encuesta/responder_encuesta/components/DatosEncuesta';
import SeccionEncuesta from '@/gquest/encuesta/responder_encuesta/components/SeccionEncuesta';
import ResponderEncuestaService from '../services/ResponderEncuestaService';
import Swal from 'sweetalert2';

export default {
  name: 'ResponderEncuesta',
  components: {
    DatosEncuesta,
    SeccionEncuesta
  },
  computed: {
    surveyFormat() {
      return this.$store.state.surveyFormat;
    },
    selection() {
      return this.$store.state.selection;
    },
    sectionsActives() {
      return this.$store.state.surveyFormat.sections.filter((element) => element.status === true);
    }
  },
  data: () => ({
    typesQuestion: [],
    responderEncuestaService: null,
    valid: false,
    validate: null,
    count: null,
    total: null,
    mostrarEncuesta: false,
    messageFormError: '',
    headOffices: [],
    businessUnits: []
  }),
  methods: {
    buscarPosicionSeccion(id) {
      let pos = 0;
      let posSection = null;
      this.surveyFormat.sections.forEach((elemento) => {
        if (elemento.id === id) {
          posSection = pos;
        }
        pos++;
      });

      return posSection;
    },
    numberInActive(id) {
      let number;
      this.sectionsActives.forEach((element, pos) => {
        if (element.id == id) number = pos + 1;
      });
      return number;
    },
    async listHeadOffice() {
      const res = await this.responderEncuestaService.get().execResource('sedes');
      if (res.status) {
        this.headOffices = res.data;
      }
    },
    async listBusinessUnit() {
      const res = await this.responderEncuestaService.get().execResource('unidadesNegocio');
      if (res.status) {
        this.businessUnits = res.data;
      }
    },
    async initialize() {
      this.showLoading = true;

      await this.listHeadOffice();
      await this.listBusinessUnit();
      const res = await this.responderEncuestaService.get().execResource('tiposPregunta');
      if (res.status) {
        this.typesQuestion = res.data;
      }

      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'id')) {
        let id = this.$route.params.id;
        const res = await this.responderEncuestaService.get().execResource('obtenerEncuestaAsignada', {
          user_quiz_id: id
        });
        if (res.status) {
          let surveyFormat = res.data[0].quiz[0];

          const fechaInicio = new Date(surveyFormat.startDate);
          const fechaFin = new Date(surveyFormat.endDate);
          const fechaActual = new Date();
          if (fechaInicio > fechaActual) {
            await this.alertDialog('error', 'La encuesta aun no inicia, no puede ingresar respuestas');
            this.$router.push({ name: 'matriz_encuestas_asignadas' });
          } else if (fechaFin < fechaActual) {
            await this.alertDialog('error', 'La encuesta ya terminó, no puede ingresar respuestas');
            this.$router.push({ name: 'matriz_encuestas_asignadas' });
          } else {
            if (res.data[0].counter === res.data[0].total) {
              await this.alertDialog('success', 'Se han completado todas las encuestas pendientes');
              this.$router.push({ name: 'matriz_encuestas_asignadas' });
            } else {
              this.count = res.data[0].counter;
              this.total = res.data[0].total;
              let showFirstSection = surveyFormat.sections[0].title === '' ? false : true;
              surveyFormat = {
                ...surveyFormat,
                showFirstSection: showFirstSection,
                isAnonimous: false,
                employeeId: null
              };
              surveyFormat.sections = surveyFormat.sections.map((section) => {
                section.questions = section.questions.map((question) => {
                  let optionsConvert = [];
                  if (Object.prototype.hasOwnProperty.call(question, 'options')) {
                    optionsConvert = question.options;
                    optionsConvert = optionsConvert.map((option) => {
                      return {
                        id: option.id,
                        title: option.title,
                        position: option.position,
                        hasComment: option.hasComment
                      };
                    });
                  }
                  question = { ...question, optionsResponse: [], response: '', optionsConvert: optionsConvert };
                  return question;
                });
                return section;
              });

              this.$store.commit('SET_FORMATO_ENCUESTA', surveyFormat);
              this.mostrarEncuesta = true;
            }
          }
        } else {
          await this.alertDialog('error', res.data);
          this.$router.push({ name: 'matriz_encuestas_asignadas' });
        }
      } else {
        this.$store.commit('CREATE_FORMATO_ENCUESTA');
      }
      this.showLoading = false;
    },
    async validateCheckbox() {
      let valid = true;
      let surveyFormat = this.$store.state.surveyFormat;
      surveyFormat.sections.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.value === 'v-checkbox' && question.required === true && question.optionsResponse.length === 0) {
            valid = false;
          }
        });
      });

      return valid;
    },
    async save() {
      let formValidate = await this.$refs.form.validate(true);
      let validateCheckbox = await this.validateCheckbox();
      this.valid = !validateCheckbox;
      if (formValidate && validateCheckbox) {
        this.messageFormError = '';
        const { isConfirmed } = await this.alertDialogYesNo('question', '¿Desea guardar sus respuestas?');

        if (isConfirmed) {
          let surveyFormat = this.$store.state.surveyFormat;
          let surveyFormatResponse = {
            idUserQuiz: this.$route.params.id,
            headOfficeId: surveyFormat.headOfficeId,
            businessUnitId: surveyFormat.businessUnitId,
            employeeId: !surveyFormat.isAnonimous ? surveyFormat.employeeId : null,
            answerQuiz: []
          };

          surveyFormat.sections.forEach((section) => {
            section.questions.forEach((question) => {
              let hasComment = false;
              let options = question.optionsResponse.map((option) => {
                let typeSelected = this.typeSelected(question.type);
                if (typeSelected.value === 'v-checkbox' || typeSelected.value === 'v-radio-group') {
                  let id = Object.prototype.hasOwnProperty.call(option, 'id') ? option.id : option;
                  option = question.optionsConvert.find((optionConvert) => optionConvert.id == id);
                  if (option.hasComment) {
                    hasComment = true;
                    if (question.comment) {
                      option.title = option.title + '(' + question.comment + ')';
                    }
                  }
                  return option.title;
                } else if (typeSelected.value === 'v-radio-group-horizontal') {
                  let id = Object.prototype.hasOwnProperty.call(option, 'id') ? option.id : option;
                  option = question.optionsConvert.find((optionConvert) => optionConvert.id == id);
                  return option.position;
                } else {
                  if (Object.prototype.hasOwnProperty.call(option, 'title') === true) return option.title;
                  return option;
                }
              });

              let response = options.join(',');
              surveyFormatResponse.answerQuiz.push({
                idQuiz: surveyFormat.id,
                idSection: section.id,
                idQuestion: question.id,
                answer: response,
                hasComment: hasComment,
                comment: question.comment
              });
            });
          });

          this.showLoading = true;
          const res = await this.responderEncuestaService
            .post()
            .execResource('guardarRespuestasEncuesta', surveyFormatResponse);
          if (res.status) {
            await this.alertDialog('success', res.data);
          } else {
            await this.alertDialog('error', res.data);
          }
          this.showLoading = false;
          this.initialize();
        }
      } else {
        this.messageFormError = 'Debe ingresar todos los campos requeridos';
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Guardar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    atras() {
      this.$router.push({ name: 'matriz_encuestas_asignadas' });
    },
    typeSelected(id) {
      let type = this.typesQuestion.find((el) => el.id === id);
      return type;
    }
  },
  created() {
    this.responderEncuestaService = this.$httpService(new ResponderEncuestaService(), this);
    this.initialize();
  }
};
</script>
